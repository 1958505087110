import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SidebarMenu from '../components/SidebarMenu';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import back from '../images/back-left.svg';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import constants from '../constants/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Toast } from 'primereact/toast';

function BookingEditPage() {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const item = location?.state?.item;
    const membersData = useStoreState((state) => state.tabModel.membersData);

    useEffect(() => {
        if(membersData && membersData?.length > 0){
            getBookingDatas();
        }
    }, [membersData]);

    const getBookingDatas = () =>{
        setIsLoading(true);
        const payload = {
            bookingId: item?.bookingId
        }
        axios
            .post(constants.URL.EDIT_BOOKING, payload)
            .then((resp) => {
                handleUserCombo(resp?.data)
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    var start = item?.clubSlot?.slotStartTimeStamp;
    const startTime = new Date(start);
    const startHour = startTime.getHours();
    const startMinute = startTime.getMinutes();
    const startPeriod = startHour >= 12 ? "pm" : "am";
    const startHour12 = startHour % 12 || 12;
    const startTimeFormatted = `${startHour12}:${startMinute < 10 ? "0" : ""}${startMinute}${startPeriod}`;
    const timeRangeFormatted = `${startTimeFormatted}`;

    const navigate = useNavigate();
    const userDetail = useStoreState((state) => state.tabModel.userDetail);
    let today = new Date(item?.clubSlot?.slotDate);
    const [date, setDate] = useState(today);
    const [noPlayers, SetNoPlayers] = useState(null);
    const [player1, setPlayer1] = useState(null);
    const [player2, setPlayer2] = useState(null);
    const [player3, setPlayer3] = useState(null);
    const [player4, setPlayer4] = useState(null);
    const setBookingResponse = useStoreActions((actions) => actions.tabModel.setBookingResponse);

    const playersArray = [];

    playersArray.push({
        userId: player1?.userId,
        slotId: item?.slotId,
        userName: player1?.userFname,
        playersName: ((item?.playerCount == "4" && !noPlayers) || (noPlayers == "4")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : ((item?.playerCount == "3" && !noPlayers) || (noPlayers == "3")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : item?.playerCount == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`,
        bookingName: userDetail?.userFname
    });
    playersArray.push({
        userId: player2?.userId,
        slotId: item?.slotId,
        userName: player2?.userFname,
        playersName: ((item?.playerCount == "4" && !noPlayers) || (noPlayers == "4")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : ((item?.playerCount == "3" && !noPlayers) || (noPlayers == "3")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : item?.playerCount == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`,
        bookingName: userDetail?.userFname
    });
    playersArray.push({
        userId: player3?.userId,
        slotId: item?.slotId,
        userName: player3?.userFname,
        playersName: ((item?.playerCount == "4" && !noPlayers) || (noPlayers == "4")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : ((item?.playerCount == "3" && !noPlayers) || (noPlayers == "3")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : item?.playerCount == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`,
        bookingName: userDetail?.userFname
    });
    playersArray.push({
        userId: ((item?.playerCount == "4" && !noPlayers) || (noPlayers == "4")) ? player4?.userId : "",
        slotId: item?.slotId,
        userName: player4?.userFname,
        playersName: ((item?.playerCount == "4" && !noPlayers) || (noPlayers == "4")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : ((item?.playerCount == "3" && !noPlayers) || (noPlayers == "3")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : item?.playerCount == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`,
        bookingName: userDetail?.userFname
    });

        const setPlayer1Handler = (e) => {
            if (e == player2 || e == player3 || e == player4) {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Duplicate Please Select another player" });
                return;
            }

            const payload = {
                slotDate: item.slotDate,
                userId: e.userId
            }

            axios.post(constants.URL.BOOKING_EXISTS, payload)
                .then((resp) => {
                    if (resp.data.status == "SUCCESS") {
                        setPlayer1(e)
                    } else {
                        toast.current.show({ severity: "error", summary: "Failure", detail: "Booking already exists for the user" });
                    }
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })

        }

        const setPlayer2Handler = (e) => {
            if (e == player1 || e == player3 || e == player4) {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Duplicate Please Select another player" });
                return;
            }

            const payload = {
                slotDate: item.slotDate,
                userId: e.userId
            }

            axios.post(constants.URL.BOOKING_EXISTS, payload)
                .then((resp) => {
                    if (resp.data.status == "SUCCESS") {
                        setPlayer2(e)
                    } else {
                        toast.current.show({ severity: "error", summary: "Failure", detail: "Booking already exists for the user" });
                    }
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })

        }

        const setPlayer3Handler = (e) => {
            if (e == player2 || e == player1 || e == player4) {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Duplicate Please Select another player" });
                return;
            }

            const payload = {
                slotDate: item.slotDate,
                userId: e.userId
            }

            axios.post(constants.URL.BOOKING_EXISTS, payload)
                .then((resp) => {
                    if (resp.data.status == "SUCCESS") {
                        setPlayer3(e)
                    } else {
                        toast.current.show({ severity: "error", summary: "Failure", detail: "Booking already exists for the user" });
                    }
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })

        }

        const setPlayer4Handler = (e) => {
            if (e == player1 || e == player2 || e == player3) {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Duplicate Please Select another player" });
                return;
            }

            const payload = {
                slotDate: item.slotDate,
                userId: e.userId
            }

            axios.post(constants.URL.BOOKING_EXISTS, payload)
                .then((resp) => {
                    if (resp.data.status == "SUCCESS") {
                        setPlayer4(e)
                    } else {
                        toast.current.show({ severity: "error", summary: "Failure", detail: "Booking already exists for the user" });
                    }
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })

        }

    const handleBookSlot = () => {
        const players = ((item?.playerCount == "4" && !noPlayers) || (noPlayers == "4")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : ((item?.playerCount == "3" && !noPlayers) || (noPlayers == "3")) ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : item?.playerCount == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`;

        const payload = {
            slotId: item?.slotId,
            bookingId: item?.bookingId,
            userId: userDetail?.userId,
            player: players,
            slotDate:item?.slotDate,
            secondaryBooking: "N",
            bookingStatus:"primary",
            createdDate: new Date(),
            userBooking: playersArray,
            playerCount: !noPlayers ? item?.playerCount : noPlayers
        }

        setIsLoading(true);
        axios.post(constants.URL.UPDATE_BOOKING, payload)
            .then((resp) => {
                if (resp.data.status == "Failure") {
                    toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.statusMessage });
                } else {
                    setBookingResponse(resp.data.response)
                    toast.current.show({ severity: "success", summary: "Success", detail: "Players Updated Successfully" });
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })

    }

    const handleUserCombo =(data)=>{
        var playerData1 = membersData?.find((item)=>{
            return item.userId == data[0]?.userId
        })
        setPlayer1(playerData1)
        var playerData2 = membersData?.find((item)=>{
            return item.userId == data[1]?.userId
        })
        setPlayer2(playerData2)
        var playerData3 = membersData?.find((item)=>{
            return item.userId == data[2]?.userId
        })
        setPlayer3(playerData3)
        var playerData4 = membersData?.find((item)=>{
            return item.userId == data[3]?.userId
        })
        setPlayer4(playerData4)
        SetNoPlayers((item?.playerCount)?.toString())
        console.log(playerData1, playerData2);
        
    }

    return (
        <div className='ds-admin-wrapper'>
            <Toast ref={toast} />
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <div>
                    <h2 className='ds-welcome-txt mb-2'>Hi, {userDetail?.userFname}</h2>
                    <p className='us-sub-text'>Welcome to Bhubaneswar Golf Club!</p>
                </div>
                <SidebarMenu />
            </div>
            <div className='ds-wrapper'>
                <Link to='/opened-slot' className='no-underline flex'>
                    <img src={back} className='mr-2' alt='arrow-back' />
                    <h2 className='bk-heading'>Booking Edit Page</h2>
                </Link>
                <div className='flex justify-content-between align-items-center os-calender my-3'>
                    <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon className='mr-3' disabled dateFormat='dd-mm-yy' />
                    <Dropdown placeholder={timeRangeFormatted} disabled />
                </div>

                <div className="flex justify-content-between align-items-center mt-2 mb-3">
                    <div className='bs-text'>Place: <span className='bs-bold-text'>Bhubaneswar Golf Club.</span></div>
                        <div className='bs-text'>
                            <span>No of Players: </span>
                            <Dropdown value={noPlayers} onChange={(e) => SetNoPlayers(e.value)} options={["3", "4"]}
                                placeholder="No of Players" className="w-6rem" />
                        </div>
                </div>

                {item?.playerCount == "1" && !noPlayers ?
                    <div className="grid bs-form-wrapper">
                        <div className="col-6">
                            <div className="field mb-2">
                                <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name</label>
                                <Dropdown options={membersData} filter optionLabel="userFname" className='w-full'
                                    placeholder="Person 1" value={player1} onChange={(e) => setPlayer1Handler(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    :
                    (item?.playerCount == "4" && !noPlayers) || (noPlayers == "4") ?
                        <div className="grid bs-form-wrapper">
                            <div className="col-6">
                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 1</label>
                                    <Dropdown options={membersData} filter optionLabel="userFname" className='w-full'
                                        placeholder="Person 1" value={player1} onChange={(e) => setPlayer1Handler(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 2</label>
                                    <Dropdown options={membersData} filter optionLabel="userFname"
                                        placeholder="Person 1" value={player2} onChange={(e) => setPlayer2Handler(e.target.value)} className='w-full' />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 3</label>
                                    <Dropdown options={membersData} filter optionLabel="userFname" className='w-full'
                                        placeholder="Person 3" value={player3} onChange={(e) => setPlayer3Handler(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 4</label>
                                    <Dropdown options={membersData} filter optionLabel="userFname" className='w-full'
                                        placeholder="Person 4" value={player4} onChange={(e) => setPlayer4Handler(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        :
                        (item?.playerCount == "3" && !noPlayers) || (noPlayers == "3") ?
                            <div className="grid bs-form-wrapper">
                                <div className="col-6">
                                    <div className="field mb-2">
                                        <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 1</label>
                                        <Dropdown options={membersData} filter optionLabel="userFname" className='w-full'
                                            placeholder="Person 1" value={player1} onChange={(e) => setPlayer1Handler(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="field mb-2">
                                        <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 2</label>
                                        <Dropdown options={membersData} filter optionLabel="userFname"
                                            placeholder="Person 1" value={player2} onChange={(e) => setPlayer2Handler(e.target.value)} className='w-full' />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="field mb-2">
                                        <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 3</label>
                                        <Dropdown options={membersData} filter optionLabel="userFname" className='w-full'
                                            placeholder="Person 3" value={player3} onChange={(e) => setPlayer3Handler(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                            :
                            item?.playerCount == "2" && !noPlayers ?
                                <div className="grid bs-form-wrapper">
                                    <div className="col-6">
                                        <div className="field mb-2">
                                            <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 1</label>
                                            <Dropdown options={membersData} filter optionLabel="userFname" className='w-full'
                                                placeholder="Person 1" value={player1} onChange={(e) => setPlayer1Handler(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="field mb-2">
                                            <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 2</label>
                                            <Dropdown options={membersData} filter optionLabel="userFname"
                                                placeholder="Person 1" value={player2} onChange={(e) => setPlayer2Handler(e.target.value)} className='w-full' />
                                        </div>
                                    </div>
                                </div>
                                : null
                }

                <div className="my-3 us-bottom-btn">
                    <Button label="Update Booking" id="large-btn" onClick={handleBookSlot} />
                </div>
            </div>
        </div>
    )
}

export default BookingEditPage;