    import React, { useEffect, useRef, useState } from 'react';
    import img1 from '../images/us-1.png';
    import img2 from '../images/welcome-poster.png';
    import { Link, useLocation, useNavigate } from 'react-router-dom';
    import SidebarMenu from '../components/SidebarMenu';
    import { Button } from 'primereact/button';
    import { Carousel } from 'primereact/carousel';
    import { Calendar } from 'primereact/calendar';
    import back from '../images/back-left.svg';
    import { Dropdown } from 'primereact/dropdown';

    import { InputSwitch } from 'primereact/inputswitch';
    import { InputText } from 'primereact/inputtext';
    import axios from 'axios';
    import constants from '../constants/constants';
    import { useStoreActions, useStoreState } from 'easy-peasy';
    import { Toast } from 'primereact/toast';

    function AdminBookingPage() {

        const toast = useRef(null);

        const [data, setData] = useState(null);
        const [configData, setConfigData] = useState(null);

        const [isLoading, setIsLoading] = useState(false);

        const location = useLocation();
        const item = location?.state?.item;

        // Access and use the 'item' object as needed
        // console.log(item);
        var start = item?.slotStartTimeStamp;
        var end = item?.slotEndTimeStamp;
        const startTime = new Date(start);
        const endTime = new Date(end);

        const startHour = startTime.getHours();
        const startMinute = startTime.getMinutes();
        const endHour = endTime.getHours();
        const endMinute = endTime.getMinutes();

        // Format the start and end times
        const startPeriod = startHour >= 12 ? "pm" : "am";
        const endPeriod = endHour >= 12 ? "pm" : "am";

        const startHour12 = startHour % 12 || 12;
        const endHour12 = endHour % 12 || 12;

        const startTimeFormatted = `${startHour12}:${startMinute < 10 ? "0" : ""}${startMinute}${startPeriod}`;
        const endTimeFormatted = `${endHour12}:${endMinute < 10 ? "0" : ""}${endMinute}${endPeriod}`;

        // Combine the start and end times
        const timeRangeFormatted = `${startTimeFormatted}`;
        // // console.log(timeRangeFormatted);

        const navigate = useNavigate();
        const userDetail = useStoreState((state) => state.tabModel.userDetail);
        let today = new Date(item?.slotDate);
        const [date, setDate] = useState(today);
        const [noPlayers, SetNoPlayers] = useState(null);
        const [player1, setPlayer1] = useState(null);
        const [player2, setPlayer2] = useState(null);
        const [player3, setPlayer3] = useState(null);
        const [player4, setPlayer4] = useState(null);
        const [checked, setChecked] = useState(false);
        const setBookingResponse = useStoreActions((actions) => actions.tabModel.setBookingResponse);
        const players = ["3", "4"];
        const MINplayer = ["2", "3", "4"];
        // // console.log(noPlayers);
        // console.log(userDetail);

        const playersArray = [];

        const setPlayer1Handler = (e) => {
            if (e == player2 || e == player3 || e == player4) {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Duplicate Please Select another player" });
                return;
            }

            const payload = {
                slotDate: item.slotDate,
                userId: e.userId
            }

            axios.post(constants.URL.BOOKING_EXISTS, payload)
                .then((resp) => {
                    if (resp.data.status == "SUCCESS") {
                        setPlayer1(e)
                    } else {
                        toast.current.show({ severity: "error", summary: "Failure", detail: "Booking already exists for the user" });
                    }
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })

        }

        const setPlayer2Handler = (e) => {
            if (e == player1 || e == player3 || e == player4) {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Duplicate Please Select another player" });
                return;
            }

            const payload = {
                slotDate: item.slotDate,
                userId: e.userId
            }

            axios.post(constants.URL.BOOKING_EXISTS, payload)
                .then((resp) => {
                    if (resp.data.status == "SUCCESS") {
                        setPlayer2(e)
                    } else {
                        toast.current.show({ severity: "error", summary: "Failure", detail: "Booking already exists for the user" });
                    }
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })

        }

        const setPlayer3Handler = (e) => {
            if (e == player2 || e == player1 || e == player4) {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Duplicate Please Select another player" });
                return;
            }

            const payload = {
                slotDate: item.slotDate,
                userId: e.userId
            }

            axios.post(constants.URL.BOOKING_EXISTS, payload)
                .then((resp) => {
                    if (resp.data.status == "SUCCESS") {
                        setPlayer3(e)
                    } else {
                        toast.current.show({ severity: "error", summary: "Failure", detail: "Booking already exists for the user" });
                    }
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })

        }

        const setPlayer4Handler = (e) => {
            if (e == player1 || e == player2 || e == player3) {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Duplicate Please Select another player" });
                return;
            }

            const payload = {
                slotDate: item.slotDate,
                userId: e.userId
            }

            axios.post(constants.URL.BOOKING_EXISTS, payload)
                .then((resp) => {
                    if (resp.data.status == "SUCCESS") {
                        setPlayer4(e)
                    } else {
                        toast.current.show({ severity: "error", summary: "Failure", detail: "Booking already exists for the user" });
                    }
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })

        }


        playersArray.push({
            userId: player1?.userId,
            slotId: item?.slotId,
            userName: player1?.userFname, 
            playersName : noPlayers == "4" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : noPlayers == "3" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : noPlayers == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`,
            bookingName: userDetail?.userFname
        });
        playersArray.push({
            userId: player2?.userId,
            slotId: item?.slotId,
            userName: player2?.userFname, 
            playersName : noPlayers == "4" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : noPlayers == "3" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : noPlayers == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`,
            bookingName: userDetail?.userFname
        });
        playersArray.push({
            userId: player3?.userId,
            slotId: item?.slotId,
            userName: player3?.userFname, 
            playersName : noPlayers == "4" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : noPlayers == "3" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : noPlayers == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`,
            bookingName: userDetail?.userFname
        });
        playersArray.push({
            userId: player4?.userId,
            slotId: item?.slotId,
            userName: player4?.userFname, 
            playersName : noPlayers == "4" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : noPlayers == "3" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : noPlayers == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`,
            bookingName: userDetail?.userFname
        });
        // console.log(playersArray);

        const handleBookSlot = () => {
            const playersLength = [player1, player2, player3, player4]?.filter((rowData) => rowData)?.length;
            if ((playersLength < configData)) {
                toast.current.show({ severity: "warn", summary: "Required", detail: "Please Fill Required Players" });
                return true;
            }

            const players = noPlayers == "4" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname},${player4?.userFname}` : noPlayers == "3" ? `${player1?.userFname},${player2?.userFname},${player3?.userFname}` : noPlayers == "2" ? `${player1?.userFname},${player2?.userFname}` : `${player1?.userFname}`;
            // console.log(player1, player2, player3, player4);
            if (!noPlayers && item?.slotStatus == "Created") {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Please select no of players" });
            }
            else {
                const payload = {
                    slotId: item?.slotId,
                    createdBy: userDetail?.userFname,
                    bookingType: item?.slotStatus == "Primary Booked" ? "Secondary" : "Primary",
                    userId: userDetail?.userId,
                    player: players,
                    secondaryBooking: "Y",
                    createdDate: new Date(),
                    playerCount: noPlayers == null ? "1" : noPlayers,
                    userBooking: playersArray,
                    primaryBookingId: item?.primaryBookingId !== null ? item?.primaryBookingId : player1?.userId,
                    bookingStatus: item?.slotStatus == "Primary Booked" ? "Request for Approval" : "Primary",
                    
                }
                setIsLoading(true);

                axios.post(constants.URL.BOOKING_SLOT, payload)
                    .then((resp) => {
                        if (resp.data.status == "Failure") {
                            toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.statusMessage });
                        } else {
                            setBookingResponse(resp.data.response)
                            navigate("/open-slot")
                        }
                    }).catch((e) => {
                        console.error(e);
                    }).finally(() => {
                        setIsLoading(false);
                    })
            }

        }

        useEffect(() => {
            setIsLoading(true);
            const payload = {}
            axios
                .post(constants.URL.LIST_USER, payload)
                .then((resp) => {
                    setData(resp.data);
                })
                .catch((e) => console.error(e))
                .finally(() => {
                    setIsLoading(false);
                });
        }, []);

        useEffect(() => {
            setIsLoading(true);
            const payload = {}
            axios
                .post(constants.URL.FETCH_CONFIG, payload)
                .then((resp) => {
                    handleMinPlayer(resp.data)
                })
                .catch((e) => console.error(e))
                .finally(() => {
                    setIsLoading(false);
                });
        }, []);

        const handleMinPlayer =(dataconfig) =>{
            if(dataconfig!=null){
                var minplayer= getConfigfromKey("minplayer",dataconfig).value;
                setConfigData(minplayer);
            }
        }
        const getConfigfromKey =(keyvalue,data) =>{
            return data.find(item => item.key === keyvalue);
        }

        return (
            <div className='ds-admin-wrapper'>
                <Toast ref={toast} />
                <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                    <div>
                        <h2 className='ds-welcome-txt mb-2'>Hi, {userDetail?.userFname}</h2>
                        <p className='us-sub-text'>Welcome to Bhubaneswar Golf Club!</p>
                    </div>
                    <SidebarMenu />
                </div>
                <div className='ds-wrapper'>
                    <Link to='/open-slot' className='no-underline flex'>
                        <img src={back} className='mr-2' alt='arrow-back' />
                        <h2 className='bk-heading'>Admin Booking Page</h2>
                    </Link>
                    <div className='flex justify-content-between align-items-center os-calender my-3'>
                        <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon className='mr-3' disabled dateFormat='dd-mm-yy' />
                        <Dropdown options={players}
                            placeholder={timeRangeFormatted} disabled />
                    </div>
                    <div className="">
                        <div className='bs-text'>Place: <span className='bs-bold-text'>Bhubaneswar Golf Club.</span></div>
                    </div>
                    <div className="flex justify-content-between align-items-center mt-2 mb-3">
                        <div className='bs-text'>Max Players Allowed: <span className='bs-bold-text'>{item?.slotStatus === "Primary Booked" ? "1" : "4"}</span></div>
                        {item?.slotStatus == "Created" ?
                            <div className='bs-text'>
                                <span>No of Players: </span>
                            <Dropdown value={noPlayers} onChange={(e) => SetNoPlayers(e.value)} options={configData == "3" ? players : MINplayer}
                                placeholder="No of Players" className="w-6rem" />
                            </div>
                            : null
                        }
                    </div>

                    {item?.slotStatus === "Primary Booked" ?
                        <div className="grid bs-form-wrapper">
                            <div className="col-6">
                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name</label>
                                    <Dropdown options={data} filter optionLabel="userFname" className='w-full'
                                        placeholder="Person 1" value={player1} onChange={(e) => setPlayer1(e.value)} />
                                </div>
                            </div>
                        </div>
                        :
                        noPlayers && noPlayers == "4" ?
                        <div className="grid bs-form-wrapper">
                            <div className="col-6">
                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 1</label>
                                    <Dropdown options={data} filter optionLabel="userFname" className='w-full'
                                        placeholder="Person 1" value={player1} onChange={(e) => setPlayer1Handler(e.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 2</label>
                                    <Dropdown options={data} filter  optionLabel="userFname"
                                        placeholder="Person 1" value={player2} onChange={(e) => setPlayer2Handler(e.value)} className='w-full' />
                                </div>
                            </div>
                            <div className="col-6">

                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 3</label>
                                    {/* <InputText id="name" type="text" className="w-full" value={player3} onChange={(e) => setPlayer3(e.target.value)} /> */}
                                    <Dropdown options={data} filter  optionLabel="userFname" className='w-full'
                                        placeholder="Person 3" value={player3} onChange={(e) => setPlayer3Handler(e.target.value)} />
                                </div>
                            </div>
                                    <div className="col-6">
                                        <div className="field mb-2">
                                            <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 4</label>
                                            {/* <InputText id="name" type="text" className="w-full" value={player4} onChange={(e) => setPlayer4(e.target.value)} /> */}
                                            <Dropdown options={data} filter  optionLabel="userFname" className='w-full'
                                                placeholder="Person 4" value={player4} onChange={(e) => setPlayer4Handler(e.target.value)} />

                                        </div>
                                    </div>
                                    
                                    </div>

                                    :
                        noPlayers && noPlayers == "3" ?
                        <div className="grid bs-form-wrapper">
                            <div className="col-6">

                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 1</label>
                                    {/* <InputText id="name" type="text" className="w-full" value={player1} readOnly onChange={(e) => setPlayer1(e.value)} /> */}
                                    <Dropdown options={data} filter  optionLabel="userFname" className='w-full'
                                        placeholder="Person 1" value={player1} onChange={(e) => setPlayer1Handler(e.value)} />
                                </div>
                            </div>
                            <div className="col-6">

                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 2</label>
                                    {/* <InputText id="name" type="text" className="w-full" value={player2} onChange={(e) => setPlayer2(e.target.value)} /> */}
                                    <Dropdown options={data} filter  optionLabel="userFname"
                                        placeholder="Person 1" value={player2} onChange={(e) => setPlayer2Handler(e.value)} className='w-full' />
                                </div>
                            </div>
                            <div className="col-6">

                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 3</label>
                                    {/* <InputText id="name" type="text" className="w-full" value={player3} onChange={(e) => setPlayer3(e.target.value)} /> */}
                                    <Dropdown options={data} filter  optionLabel="userFname" className='w-full'
                                        placeholder="Person 3" value={player3} onChange={(e) => setPlayer3Handler(e.target.value)} />
                                </div>
                            </div>
                                    
                        </div>
                        : 
                        noPlayers && noPlayers == "2" ?
                        <div className="grid bs-form-wrapper">
                            <div className="col-6">

                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 1</label>
                                    {/* <InputText id="name" type="text" className="w-full" value={player1} readOnly onChange={(e) => setPlayer1(e.value)} /> */}
                                    <Dropdown options={data} filter  optionLabel="userFname" className='w-full'
                                        placeholder="Person 1" value={player1} onChange={(e) => setPlayer1Handler(e.value)} />
                                </div>
                            </div>
                            <div className="col-6">

                                <div className="field mb-2">
                                    <label htmlFor="name" className="block text-900 font-medium mb-2">Players Name 2</label>
                                    {/* <InputText id="name" type="text" className="w-full" value={player2} onChange={(e) => setPlayer2(e.target.value)} /> */}
                                    <Dropdown options={data} filter  optionLabel="userFname"
                                        placeholder="Person 1" value={player2} onChange={(e) => setPlayer2Handler(e.value)} className='w-full' />
                                </div>
                            </div>
                                    
                        </div>
                        : null

                    }

                    {/* <Link to='/user-booked' className='no-underline w-6'> */}
                    <div className="my-3 us-bottom-btn">
                        <Button label="Book Slot" id="large-btn" onClick={handleBookSlot} />
                    </div>
                    {/* </Link> */}
                </div>
            </div>
        )
    }

    export default AdminBookingPage;