import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import trash from '../images/icon_trash.png';
import SidebarMenu from '../components/SidebarMenu';
import back from '../images/icon_arrow_left.svg';
import axios from 'axios';
import constants from '../constants/constants';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';

function Members() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userDetail = useStoreState((state) => state.tabModel.userDetail);
    const setMembersData = useStoreActions((actions) => actions.tabModel.setMembersData);

    useEffect(() => {
        handleMembersList()
    }, []);

    const handleMembersList = () => {
        setIsLoading(true);
        const payload = {}
        axios
            .post(constants.URL.LIST_USER, payload)
            .then((resp) => {
                // console.log(resp);
                setData(resp.data);
                setMembersData(resp.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const confirm1 = (item) => {
        confirmDialog({
            message: "Are you sure you want to Delete?",
            header: "Delete Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => handleDelete(item),
        });
    };

    const handleDelete = (item) => {
        // console.log(item);
        const payload = {
            userId: item?.userId
        }
        // console.log(payload);
        axios
            .post(constants.URL.DELETE_USER, payload)
            .then((resp) => {
                // console.log(resp);
                handleMembersList()
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className='os-admin-wrapper'>
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/dashboard' className='no-underline w-6'>
                    <img src={back} />
                </Link>
                <SidebarMenu />
            </div>
            <div className='os-inner pt-0'>
                <div className='flex justify-content-between align-items-center mb-2 pb-1'>
                    <h2 className='os-heading'>Members</h2>
                    {userDetail?.roles[0]?.roleId === 1 ?
                        <div className='ms-addmember-btn cursor-pointer' onClick={() => navigate("/add-member")}>Add Members</div>
                    : null}
                </div>

                <div className='card flex justify-content-between align-items-center os-card-title'>
                    <div className='w-3'>Name</div>
                    <div className='w-3'>Phone No</div>
                    <div className='w-3'>Member Id</div>
                    <div className='w-3'></div>
                </div>

                {isLoading ? <small className='m-3 pb-4'>Loading...</small> : null}
                {data?.map((item) => {
                    return (
                        <div className=''>
                            <div className='card flex justify-content-between align-items-center oss-card-inner'>
                                <div className='w-3'>{item?.userFname}</div>
                                <div className='w-5'>{item?.userMobile}</div>
                                <div className='w-5'>{item?.membershipNumber}</div>
                                <Toast ref={toast} />

                                {userDetail?.roles[0]?.roleId === 1 ?
                                    <>
                                        <i className='pi pi-pencil pr-3 cursor-pointer' onClick={() => {navigate("/add-member", { state: item })}} style={{ fontSize: '12px' }} />
                                        <img src={trash} onClick={() => confirm1(item)} alt='img' width="12px" height="13.3px" className='cursor-pointer' />
                                    </>: 
                                        <i className='pi pi-pencil cursor-pointer' onClick={() => {navigate("/add-member", { state: item })}} style={{ fontSize: '12px' }} />}
                            </div>
                        </div>
                    )
                })}
            </div>

            <ConfirmDialog />
        </div>
    )
}

export default Members;